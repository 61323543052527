import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import alertReducer from './alertDuck';
import menuReducer from './menuDuck';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let rootReducer = combineReducers({
    alert: alertReducer,
    menu: menuReducer
})

export default function generateStore() {
    let store = createStore(rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    )

    // if(store.getState().user.loggedIn==true){
    //     getCart()(store.dispatch, store.getState)
    //     getCurrentUser()(store.dispatch, store.getState)
    //     getCategories()(store.dispatch,store.getState)
    // }

    return store
}
