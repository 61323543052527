export let getHeaders = (is_image) => {
    let content_type = (is_image === true) ? "multipart/form-data" : "application/json"

    // let test = JSON.parse(localStorage);

    let access_token = ""

    if (localStorage.storage !== undefined) {
        access_token = JSON.parse(localStorage.storage).access_token
    }


    return {
        "Authorization": 'Bearer ' + access_token,
        "Content-Type": content_type,
        "business": "manaexpress",
        "Accept": "application/json",
    }
}

export let removeSession = () => {
    console.log('removesession');

    localStorage.removeItem("token")
    localStorage.removeItem("storage")
    localStorage.removeItem("address")
}

export let saveStorage = (storage) => {
    localStorage.storage = JSON.stringify(storage)
    localStorage.token = storage.access_token
}

export const validateInput = (form, rules) => {
    let err = {}

    for (const [key, value] of Object.entries(form)) {
        rules.map(val => {
            if (key === val.field) {
                if (value === '' || value === 0) {
                    err[key] = val.msg
                }

                if (val.maxlength !== undefined) {

                    if (value.length === (val.maxlength - 1)) {
                        err[key] = `Longitud minima ${val.maxlength}`
                    }
                }
            }



        });
    }

    return Object.keys(err).length === 0 ? null : err
}




export let formatNumber = (number) => {
    return new Intl.NumberFormat('de-DE').format(number)
    // return parseFloat(number).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}