import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { useHistory } from 'react-router-dom';
import TemplateMenu from '../../template/menu.template';
import { getMenu, getBannerByBranch } from '../../redux/menuDuck'
import './index.css'
import ModalCategory from './modal.category';
import whatsapp from '../../images/whatsapp-icon.png'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ModalPopUp from '../menu/modal.popup';

const API_URL = process.env.REACT_APP_API_URL


const HomePage = ({ getMenu, fetching_list, data, name, getBannerByBranch, fetching_banner_branch,
    data_banner_branch }) => {
    const history = useHistory()
    const [list, setList] = useState([])
    const [modal, setModal] = useState(false)
    const [phone, setPhone] = useState('')
    const [modalBanner, setModalBanner] = useState(false)
    const [banner, setBanner] = useState('')


    let { wa, branch } = useParams();

    useEffect(() => {
        setPhone(wa)
        getMenu(branch)
        getBannerByBranch(branch)
    }, [wa, branch])

    useEffect(() => {
        if (fetching_banner_branch === 2) {
            if (data_banner_branch.length > 0) {
                setBanner(data_banner_branch[0].real_image)
                setModalBanner(true)
            } else {
                setModalBanner(false)
            }
        }
    }, [fetching_banner_branch])


    useEffect(() => {
        if (fetching_list === 2) {
            setList(data)
        }
    }, [fetching_list])

    const onSelectCategory = (category) => {
        setModal(false)
        //window.location.href = `#${category.category}`
    }

    return (

        <Container fluid className='px-0 main-container'>

            <div id="images">
                {list.map((d, k) => <div key={k} id={`${d.category}`} className="main-category">

                    {d.nodes.map((n, key) => {
                        let number = (n < 10) ? '0' + n : n

                        return <div key={key} className="row p-0 m-0 d-flex justify-content-center" >
                            <div className="col p-0 m-0 col-lg-5">
                                <img src={`${API_URL}/${name}${number}.png`} />
                            </div>
                        </div>
                    })}
                </div>)}
            </div>

            <div className="floating-button cursor-pointer" onClick={() => setModal(true)}>
                <div className="floating-button-circle">Menú</div>
            </div>

            <div className="floating-button-wa cursor-pointer">
                <a href={`https://api.whatsapp.com/send?phone=${phone}`} >
                    <img src={whatsapp} />
                </a>
            </div>

            <ModalCategory show={modal} list={list} onHide={() => setModal(false)} onSelectCategory={onSelectCategory} />

            <ModalPopUp show={modalBanner} banner={banner} onHide={() => setModalBanner(false)} />
        </Container>
    )
}

const mapsState = (props) => {
    let { menu } = props

    return {
        fetching_list: menu.fetching_list,
        fetching_banner_branch: menu.fetching_banner_branch,
        data_banner_branch: menu.data_banner_branch,
        data: menu.data,
        name: menu.name,

    }
}

export default connect(mapsState, { getMenu, getBannerByBranch })(HomePage);