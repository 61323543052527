import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalCategory = ({ show, list, onHide, onSelectCategory }) => {


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Categorias</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {list.map((d, k) => <Row key={k} className='my-1'>
                    <Col className='cursor-pointer' onClick={() => onSelectCategory(d)}>
                        <a href={`#${d.category}`}>{d.title}</a></Col>
                </Row>)}

            </Modal.Body>
        </Modal>
    )
}

export default ModalCategory;