import HomePage from "./page/home";
import { Switch, Route } from "react-router-dom";
import NotFoundPage from "./page/not_found";
import RoutesPrivate from "./RoutesPrivate";
import LoginPage from "./page/login";
import MenuPage from "./page/menu";
// import PrivateRoute from './RoutesPrivate'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={MenuPage} />
      <Route exact path="/menu/:wa/:branch" component={HomePage} />
      <Route exact path="/login" component={LoginPage} />

      <Route path="*" exact={true} component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
