import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import ButtonMenu from './ button.menu';
import logo_menu from '../../images/logo_menu_color.svg'
import menu_footer from '../../images/menu_footer.svg'
import ModalPopUp from './modal.popup';
import { getBanner } from '../../redux/menuDuck'
import { connect } from 'react-redux';
import ig_logo from '../../images/ig_logo.png'
import tiktok from '../../images/tiktok.png'


const MenuPage = ({ getBanner, fetching_banner, data_banner }) => {

    const [banner, setBanner] = useState('')
    const [modalBanner, setModalBanner] = useState(false)

    useEffect(() => {
        if (fetching_banner === 2) {
            if (data_banner.length > 0) {
                setBanner(data_banner[0].real_image)
                setModalBanner(true)
            } else {
                setModalBanner(false)
            }
        }
    }, [fetching_banner])

    useEffect(() => {
        getBanner()
    }, [])


    return (
        <Container fluid className='h-100'>
            <Row className='d-flex justify-content-center h-100 align-items-center'>
                <Col lg={3} xs={9}>

                    <Row className='mt-4 d-flex justify-content-center'>
                        <Col className='text-center' lg={8}>
                            <img src={logo_menu} className='img-fluid cursor-pointer' />
                        </Col>
                    </Row>
                    <Row className='my-5'>
                        <Col className='text-center'>
                            Escoge el <b>menú</b> de la sede<br />
                            donde estás ubicado
                        </Col>
                    </Row>

                    <Row className=''>
                        <Col>
                            <ButtonMenu title="Menú Hayuelos" to="https://menu.onixcontrol.com/menu/573017265558/11" />
                            {/* <ButtonMenu title="Menú Hayuelos" to="http://localhost:3002/menu/573017265558/11" /> */}
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <ButtonMenu title="Menú Capriani" to={"https://menu.onixcontrol.com/menu/573209670919/12"} />
                        </Col>

                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <ButtonMenu title="Menú Calle 80" to={"https://menu.onixcontrol.com/menu/573124497147/12"} />
                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <ButtonMenu title="Menú Campiña" to={"https://menu.onixcontrol.com/menu/573245849093/12"} />
                        </Col>
                    </Row>
                    {/* <Row className='my-3'>
                        <Col>
                            <ButtonMenu title="Menú Barrancas" to={"https://menu.onixcontrol.com/menu/573006064366/12"} />
                        </Col>
                    </Row> */}
                    <Row className='my-3'>
                        <Col>
                            <ButtonMenu title="Menú Calera" to={"https://menu.onixcontrol.com/menu/573012863143/0"} />
                            {/* <ButtonMenu title="Menú Calera" to={"http://localhost:3002/menu/573012863143/0"} /> */}

                        </Col>
                    </Row>
                    <Row className='my-3'>
                        <Col>
                            <ButtonMenu title="Menú Almendros" to={"https://menu.onixcontrol.com/menu/573017472483/12"} />

                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="floating-button-ig-left cursor-pointer">
                <a href={`https://www.instagram.com/dontelmo1958/`} >
                    <img src={ig_logo} />
                </a>
            </div>
            <div className="floating-button-ig-right cursor-pointer">
                <a href={`https://www.instagram.com/dontelmo1958_/`} >
                    <img src={ig_logo} />
                </a>
            </div>
            <div className="floating-button-tiktok cursor-pointer">
                <a href={`https://www.tiktok.com/@dontelmo1958_`} >
                    <img src={tiktok} />
                </a>
            </div>
            <ModalPopUp show={modalBanner} banner={banner} onHide={() => setModalBanner(false)} />
        </Container>

    )
}


const mapsState = (props) => {
    let { menu } = props
    return {
        fetching_banner: menu.fetching_banner,
        data_banner: menu.data_banner,
    }
}
export default connect(mapsState, { getBanner })(MenuPage);