import React from 'react'
import { Col, Row } from 'react-bootstrap';
import './index.css'
import { IoChevronForward } from 'react-icons/io5';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


const ButtonMenu = ({ title, to }) => {
    return (
        <a href={to} className='btn-menu'>
            <div className='btn-menu-item'>{title}</div>
            <div className='btn-menu-icon'><IoChevronForward color='ff8000' /></div>
        </a>
    )
}

export default ButtonMenu;