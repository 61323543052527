import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { SocketProvider } from './context/SocketContext';
import generateStore from './redux/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';


let store = generateStore()

let WithRouter = () => <BrowserRouter>
  <App />
</BrowserRouter>


let WithStore = () => (<Provider store={store} >
  {/* <SocketProvider> */}
  <WithRouter />
  {/* </SocketProvider> */}
</Provider>)


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <WithStore />
//   </React.StrictMode>
// );
root.render(
  <WithStore />
);
// serviceWorker.register()