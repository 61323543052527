import { getHeaders } from '../utils'
const API_URL = process.env.REACT_APP_API_URL

let initialState = {
    fetching_list: 0,
    fetching_banner: 0,
    fetching_banner_branch: 0,
    data: [],
    data_banner: [],
    data_banner_branch: [],
    name: ''
}


const LIST_MENU = "LIST_MENU"
const LIST_MENU_SUCCESS = "LIST_MENU_SUCCESS"
const DEFAULT_ERROR = "DEFAULT_ERROR"

const LIST_BANNER = "LIST_BANNER"
const LIST_BANNER_SUCCESS = "LIST_BANNER_SUCCESS"
const LIST_BANNER_BRANCH = "LIST_BANNER_BRANCH"
const LIST_BANNER_BRANCH_SUCCESS = "LIST_BANNER_BRANCH_SUCCESS"

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_MENU:
            return { ...state, fetching_list: 1 }
        case LIST_MENU_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload, name: action.name }
        case LIST_BANNER:
            return { ...state, fetching_banner: 1 }
        case LIST_BANNER_SUCCESS:
            return { ...state, fetching_banner: 2, data_banner: action.payload }
        case LIST_BANNER_BRANCH:
            return { ...state, fetching_banner_branch: 1 }
        case LIST_BANNER_BRANCH_SUCCESS:
            return { ...state, fetching_banner_branch: 2, data_banner_branch: action.payload }
        default:
            return state;
    }
}

export let getMenu = (branch) => dispatch => {
    dispatch({
        type: LIST_MENU
    })

    let url = `${API_URL}/api/menu-digital?b=${branch}`;

    fetch(url, {
        method: "get",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        if (resp.ok === false) {
            // dispatch({
            //     type: LOGIN_ERROR,
            //     payload: resp.errors
            // })
        } else {

            dispatch({
                type: LIST_MENU_SUCCESS,
                payload: resp.categories,
                name: resp.name
            })
        }
    })
}

export let getBanner = () => dispatch => {
    dispatch({
        type: LIST_BANNER
    })

    let url = `${API_URL}/api/banner-client`;

    fetch(url, {
        method: "get",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        if (resp.ok === false) {
            // dispatch({
            //     type: LOGIN_ERROR,
            //     payload: resp.errors
            // })
        } else {


            dispatch({
                type: LIST_BANNER_SUCCESS,
                payload: resp.results,
            })
        }
    })
}
export let getBannerByBranch = (branch) => dispatch => {
    dispatch({
        type: LIST_BANNER_BRANCH
    })

    let url = `${API_URL}/api/banner-branch/${branch}`;

    fetch(url, {
        method: "get",
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {

        if (resp.ok === false) {
            // dispatch({
            //     type: LOGIN_ERROR,
            //     payload: resp.errors
            // })
        } else {


            dispatch({
                type: LIST_BANNER_BRANCH_SUCCESS,
                payload: resp.results,
            })
        }
    })
}