import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap';

const ModalPopUp = ({ show, banner, onHide }) => {
    return (
        <Modal show={show} onHide={onHide} centered className='px-3'>
            <Modal.Body className='p-0 container-image'>
                <div className='btn-close-banner' onClick={onHide}>X</div>
                <Row className='d-flex justify-content-center'>
                    <Col>
                        <img src={banner} className='img-fluid' />
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default ModalPopUp;